import { PFCType } from "../types/PFC";

export type MenuElementViewModel = {
  name: string;
  calories: number;
  pfc: PFCType;
  isIngredient: boolean;
}

export type AppViewModel = {
  totalCalories: number;
  totalPFC: PFCType;

  comment: string;
  imageURL: string;

  menus: MenuElementViewModel[];
};

export class APIClient {
  constructor(
    private baseURL: string
  ) {}

  async fetch(recognitionID: string): Promise<AppViewModel> {
    console.log("fetch", recognitionID);
    const url = `${this.baseURL}/recognition/${recognitionID}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw { state: "error", message: "サーバーからエラーが返されました。" };
    }
    const data = await response.json();
    console.log("data", data);
    const state = data.state;

    if (state !== "completed") {
      if (state === "queued" || "processing") {
        throw { state: "error", message: "処理中です。しばらくお待ちください。" };
      }
      if (state === "failed") {
        throw { state: "error", message: "エラーが発生しました。" };
      }
    }

    const comment = data.comment;
    if (typeof comment !== "string") {
      throw { state: "error", message: "コメントが見つかりません。" };
    }

    const menus = data.menus;
    if (!Array.isArray(menus)) {
      throw { state: "error", message: "メニューが見つかりません。" };
    }

    const menuElement = menus.map((menu: any) => this.parseMenuElement(menu));

    const nonIngredientMenus = menuElement.filter((element) => !element[0].isIngredient);
    const totalCalories = nonIngredientMenus.reduce((acc, element) => acc + element[0].calories, 0);
    const totalPFC = nonIngredientMenus.reduce((acc, element) => {
      return {
        protein: acc.protein + element[0].pfc.protein,
        fat: acc.fat + element[0].pfc.fat,
        carbs: acc.carbs + element[0].pfc.carbs
      }
    }, { protein: 0, fat: 0, carbs: 0 });

    return {
      totalCalories: totalCalories,
      totalPFC: totalPFC,
      imageURL: data.imageURL,
      comment,
      menus: menuElement.flat()
    }
  }

  private parseMenuElement(menu: any): MenuElementViewModel[] {
    if (menu.type === "standalone") {
      return [{
        name: `${menu.name} (${menu.quantity}${menu.mainunit.name})`,
        calories: menu.unitCalories,
        pfc: menu.unitPFC,
        isIngredient: false
      }]
    } else {
      const elements: MenuElementViewModel[] = [];
      let totalCalories = 0;
      let totalPFC = { protein: 0, fat: 0, carbs: 0 };

      for (const ingredient of menu.ingredients) {
        const element = {
          name: `${ingredient.name} (${ingredient.quantity}${ingredient.mainunit.name})`,
          calories: ingredient.unitCalories,
          pfc: ingredient.unitPFC,
          isIngredient: true
        };
        elements.push(element);
        totalCalories += element.calories;
        totalPFC.protein += element.pfc.protein;
        totalPFC.fat += element.pfc.fat;
        totalPFC.carbs += element.pfc.carbs;
      }

      elements.unshift({
        name: `${menu.name} (${menu.quantity}${menu.mainunit.name})`,
        calories: totalCalories,
        pfc: totalPFC,
        isIngredient: false
      });

      return elements;
    }
  }
}

// export const MealRecognitionState = ["queued", "processing", "completed", "failed"] as const;
// export const MealRecognitionFailureReason = ["meal_not_found", "system_error", "media_download_error"] as const;