import styled from "styled-components"

export const AppFeatureList = (
  { children }:
  { children: React.ReactNode }
) => {
  return (
    <AppFeatureListContainer>
      <AppFeatureListTitle>もっと！アプリの機能</AppFeatureListTitle>
      {children}
    </AppFeatureListContainer>
  );
}

export const AppFeatureSeparator = styled.div`
  height: 1px;
  width: 100%;
  background: #e0e0e0;
`;

const AppFeatureListTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
`;

const AppFeatureListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: white;
  width: 100%;
  padding: 16px;

  @media (min-width: 400px) {
    border-radius: 18px;
  }
`;