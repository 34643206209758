import styled from 'styled-components';

export const ContentContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <ContentContainerContainer>
      <ContentContainerCard>
        {children}
      </ContentContainerCard>
    </ContentContainerContainer>
  );
}

const ContentContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 0;
  background-color: white;
  width: 100%;
  max-width: 400px;

  @media (min-width: 400px) {
    border-radius: 18px;
  }
`;

const ContentContainerContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 100%;
`;