import styled from "styled-components";
import { UnitValue } from "./UnitValue";
import { PFCComponents } from "./PFCComponents";
import { PFCType } from "../types/PFC";

export const ImageCard = (
    { imageURL, calories, pfc }:
    { imageURL: string, calories: number, pfc: PFCType }
) => {
    return (
        <ImageCardContainer>
            <ImageCardImage src={imageURL} />
            <ImageCardShadow>
                <CaloriesContainer>
                    <CaloriesText>合計カロリー</CaloriesText>
                    <UnitValue value={calories} unit="kcal" size="large"/>
                </CaloriesContainer>
              <PFCComponents protein={pfc.protein} fat={pfc.fat} carbs={pfc.carbs} />
            </ImageCardShadow>
        </ImageCardContainer>
    );
}

const CaloriesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;


const CaloriesText = styled.span`
    font-size: 0.8rem;
    font-weight: 600;
    opacity: 0.8;
`;


const ImageCardShadow = styled.div`
    width: 100%;
    bottom: 0;
    padding: 24px 16px 8px 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    position: absolute;
    color: white;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: last baseline
`;

const ImageCardContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    background-color: gray;
    border-radius: 12px;
    height: 210px;
    overflow: hidden;
`;

const ImageCardImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;