import styled from "styled-components";
import { PFCType } from "../types/PFC";
import { PFCComponents } from "./PFCComponents";
import { UnitValue } from "./UnitValue";

export const MenuItem = (
  { name, calories, pfc, inset = false }: 
  { name: string, calories: number, pfc: PFCType, inset?: boolean }
) => {
  
  return (
    <MenuItemContainer inset={inset}>
      <MenuItemTitle inset={inset}>{name}</MenuItemTitle>
      <MenuItemMetaContainer>
        <UnitValue value={calories} unit="kcal" size="small" color="#00000057" />
        <PFCComponents protein={pfc.protein} fat={pfc.fat} carbs={pfc.carbs} size="tiny" />
      </MenuItemMetaContainer>
    </MenuItemContainer>
  );
}

export const MenuItemTitle = styled.span<{ inset: boolean }>`
  font-size: 0.95rem;
  font-weight: ${({ inset }) => inset ? 500 : 550};
  color: ${({ inset }) => inset ? '#000000b2' : 'black'};
`;

const MenuItemContainer = styled.div<{ inset: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: left;
  padding: 6px 0;
  border-bottom: 1px solid #00000015;
  margin-left: ${({ inset }) => inset ? '24px' : '0px'};
`;

const MenuItemMetaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between
`;