import styled from "styled-components";
import proteinTip from "../assets/protein-tip.svg";
import fatTip from "../assets/fat-tip.svg";
import carbsTip from "../assets/carbs-tip.svg";
import { UnitValue, UnitValueSize } from "./UnitValue";

export const PFCComponents = (
  { protein, fat, carbs, size = "small" }:
  { protein: number; fat: number; carbs: number, size?: UnitValueSize }
) => {
  return (
    <PFCComponentsContainer size={size}>
      <PFCComponentsTip src={proteinTip} alt="Protein" size={size} />
      <UnitValue value={protein} unit="g" size={size} color="#F48649" />
      <PFCComponentsTip src={fatTip} alt="Fat" size={size} />
      <UnitValue value={fat} unit="g" size={size} color="#FAC115" />
      <PFCComponentsTip src={carbsTip} alt="Carbs" size={size} />
      <UnitValue value={carbs} unit="g" size={size} color="#7DB657" />
    </PFCComponentsContainer>
  );
}

const tipSize = (size: UnitValueSize) => {
  if (size === "tiny") {
    return "12px";
  } else if (size === "small") {
    return "16px";
  } else if (size === "medium") {
    return "20px";
  } else {
    return "24px";
  }
}

const containerSpacing = (size: UnitValueSize) => {
  if (size === "tiny") {
    return "4px";
  } else if (size === "small") {
    return "5px";
  } else if (size === "medium") {
    return "8px";
  } else {
    return "12px";
  }
}

export const PFCComponentsTip = styled.img<{ size: UnitValueSize }>`
  height: ${({ size }) => tipSize(size)};
`;

export const PFCComponentsContainer = styled.div<{ size: UnitValueSize }>`
display: flex;
flex-direction: row;
align-items: center;
gap: ${({ size }) => containerSpacing(size)};
`;
