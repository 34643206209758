import styled from 'styled-components';

export const CommentContainer = (
  { comment }:
  { comment: string }
) => {
  return (
    <CommentContainerContainer>
      <h4>AIのコメント</h4>
      <p>{comment}</p>
    </CommentContainerContainer>
  );
}

const CommentContainerContainer = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
border-radius: 12px;
background: #F2F2F6;
padding: 8px 16px;

h4 {
  font-size: 1.1rem;
  font-weight: bold;
}

p {
  font-size: 0.9rem;
  opacity: 0.6;
}
`;