import styled from 'styled-components';

export const AppBodyLayout = (
  { children }:
  { children: React.ReactNode }
) => {
  return (
    <AppBodyLayoutContainer>
      {children}
    </AppBodyLayoutContainer>
  );
}

const AppBodyLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media (min-width: 800px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 8px;
  }
`;

export const AppSidebarLayout = (
  { children }:
  { children: React.ReactNode }
) => {
  return (
    <AppLayoutContainer>
      {children}
    </AppLayoutContainer>
  );
}

const AppLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;
`;