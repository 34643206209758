import styled from "styled-components";
import appIcon from "../assets/app-icon.svg";
import appStore from "../assets/app-store.svg";

export const AppStoreItem = (
  { name, description, url }:
  { name: string, description: string, url: string }
) => {
  return (
    <AppStoreItemContainer>
      <a href={url} target="_blank" rel="noreferrer">
        <AppIcon src={appIcon} alt="App Icon" />
      </a>
      <AppMetaContainer>
        <AppTitle href={url} target="_blank" rel="noreferrer">{name}</AppTitle>
        <AppDescription>{description}</AppDescription>
      </AppMetaContainer>
      <a href={url} target="_blank" rel="noreferrer">
        <AppStoreImage src={appStore} alt="App Store" />
      </a>
    </AppStoreItemContainer>
  );
}

const AppStoreImage = styled.img`
`;

const AppIcon = styled.img`
  width: 64px;
  height: 64px;
`;

const AppTitle = styled.a`
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: black;
`;

const AppDescription = styled.p`
  font-size: 0.9;
  opacity: 0.6;
  text-align: center;
`;

const AppMetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between
`;

const AppStoreItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px;
  background: white;

  max-width: 400px;

  @media (min-width: 400px) {
    border-radius: 18px;
  }

  
`;