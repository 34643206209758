import styled from 'styled-components';

export const UnitValueSize = ["tiny", "small", "medium", "large"] as const;
export type UnitValueSize = typeof UnitValueSize[number];

export const UnitValue = (
  { value, unit, size, color }:
  { value: number; unit: string, size?: UnitValueSize, color?: string }
) => {
  const rounded10String = (Math.round(value * 10) / 10).toString();
  const roundedValue = (Math.round(value)).toString();

  let valueString = rounded10String;

  if (valueString.length >= 4) {
    valueString = roundedValue;
  }

  return (
    <UnitValueContainer size={size ?? "medium"} color={color}>
      {valueString}
      <span>{unit}</span>
    </UnitValueContainer>
  );
}

const unitValueGap = (size: UnitValueSize) => {
  if (size === "tiny") {
    return "2px";
  } else if (size === "small") {
    return "2px";
  } else if (size === "medium") {
    return "3px";
  } else {
    return "4px";
  }
}

const unitValueFontSize = (size: UnitValueSize) => {
  if (size === "tiny") {
    return "0.75rem";
  } else if (size === "small") {
    return "0.8rem";
  } else if (size === "medium") {
    return "1rem";
  } else {
    return "2rem";
  }
}

const unitValueFontSizeSpan = (size: UnitValueSize) => {
  if (size === "tiny") {
    return "0.7rem";
  } else if (size === "small") {
    return "0.7rem";
  } else if (size === "medium") {
    return "0.9rem";
  } else {
    return "1.3rem";
  }
}

const UnitValueContainer = styled.span<{ size: UnitValueSize, color?: string }>`
  color: ${({ color }) => color };
  display: inline-flex;
  align-items: baseline;
  gap: ${({ size }) => unitValueGap(size)};
  font-size: ${({ size }) => unitValueFontSize(size)};
  font-weight: bold;

  span {
    opacity: 0.7;
    font-size: ${({ size }) => unitValueFontSizeSpan(size)};
  }
`;