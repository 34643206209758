import styled from "styled-components";

import logo from '../assets/logo.svg'; // ロゴのパスを正しく指定

export const Header = () => {
    return (
        <HeaderContainer>
            <Logo src={logo} alt="Logo" />
        </HeaderContainer>
    );
};

export default Header;

const HeaderContainer = styled.header`
    display: flex;
    justify-content: center; /* デフォルトは中央に配置 */
    align-items: center;
    padding: 12px;
    width: 100%;
    background-color: white;

    @media (min-width: 768px) {
        justify-content: flex-start; /* PCでは左端に配置 */
    }
`;

const Logo = styled.img`
    height: 32px;
    /* 必要に応じて他のスタイルを追加 */
`;
