import React, { useEffect } from 'react';
import { Header } from './Header';
import { ContentContainer } from './ContentContainer';
import styled from 'styled-components';
import { ImageCard } from './ImageCard';
import { CommentContainer } from './CommentContainer';
import { MenuList } from './MenuList';
import { MenuItem } from './MenuItem';
import { AppStoreItem } from './AppStoreItem';
import { AppBodyLayout, AppSidebarLayout } from './AppLayout';
import { APIClient, AppViewModel } from '../client/APIClient';
import { AppFeatureList, AppFeatureSeparator } from './AppFeatureList';
import { AppFeatureItem } from './AppFeatureItem';
import appFeatureQuantityImage from '../assets/app_feature_quantity.png';
import appFeatureCommentImage from '../assets/app_feature_comment.png';
import appFeatureSearchImage from '../assets/app_feature_search.png';

const client = new APIClient("https://caloriai-ad-b0eja9cqeuh5amcc.japaneast-01.azurewebsites.net");

export const App = () => {

  const [viewModel, setViewModel] = React.useState<AppViewModel | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<Error | null>(null);
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    if (id  == null) {
      setIsLoading(false);
      setError(new Error("IDが指定されていません"));
      return;
    }

    client.fetch(id)
      .then((viewModel) => {
        console.log("viewModel", viewModel)
        setViewModel(viewModel);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  } ,[]);



  return (
    <AppContainer>
      <Header/>
      <AppBodyLayout>
        <ContentContainer>
          {
            isLoading ? 
            (
              <AppMessage>ロード中...</AppMessage>
            ) : error ? (
              <AppMessage>{error.message}</AppMessage>
            ) : viewModel ? (
              <>
                <ImageCard
                  imageURL={viewModel.imageURL}
                  calories={viewModel.totalCalories}
                  pfc={viewModel.totalPFC}
                />      
                <CommentContainer comment={viewModel.comment}/>
                <MenuList>
                  {
                    viewModel.menus.map((menu, index) => (
                      <MenuItem
                        key={index}
                        name={menu.name}
                        calories={menu.calories}
                        pfc={menu.pfc}
                        inset={menu.isIngredient}
                      />
                    ))
                  }
                </MenuList>
              </>
            ) : null
          }
        </ContentContainer>
        
        <AppSidebarLayout>
          <AppStoreItem
            name='カロリAI - AIが写真からカロリーを計算'
            description='カメラで写真を撮るだけで、食事のカロリーをAIが計算します。'
            url='https://apps.apple.com/app/id6504136520'
          />
          <AppFeatureList>
            <AppFeatureItem
              title='アプリでは量の調整も可能！'
              description='アプリではAI認識後に量を調整できます。量が間違っていた場合でも簡単に手直し可能！'
              image={appFeatureQuantityImage}
            />
            <AppFeatureItem
              title='毎日のカロリーを記録してもっと詳細なアドバイス'
              description='アプリで毎日のカロリーを記録すれば、記録に基づいてさらに詳細なコメントが返ってきます！'
              image={appFeatureCommentImage}
            />
            <AppFeatureItem
              title='バーコードやレストランのメニューから検索'
              description='アプリではバーコードをスキャンしたり、レストランのメニューから検索して食事を記録できます。'
              image={appFeatureSearchImage}
            />
          </AppFeatureList>
        </AppSidebarLayout>
      </AppBodyLayout>

      <AppCopyRight>© 2024 AZStudio inc.</AppCopyRight>
    </AppContainer>
  );

  
}

const AppCopyRight = styled.p`
text-align: center;
opacity: 0.6;
`;

const AppContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 16px;
padding-bottom: 24px;
`;

const AppMessage = styled.p`
text-align: center;
opacity: 0.6;
`