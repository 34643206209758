import styled from "styled-components";

export const MenuList = (
  { children }:
  { children: React.ReactNode }
) => {
  return (
    <MenuListContainer>
      {children}
    </MenuListContainer>
  );
}

const MenuListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;