import styled from "styled-components"

export const AppFeatureItem = (
  { title, description, image }:
  { title: string, description: string, image: string }
) => {
  return (
    <AppFeatureItemContainer>
      <AppFeatureTitle>{title}</AppFeatureTitle>
      <AppFeatureDescription>{description}</AppFeatureDescription>
      <AppFeatureImage src={image} alt={title} />
    </AppFeatureItemContainer>
  );
}

const AppFeatureTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: bold;
`;

const AppFeatureDescription = styled.p`
  font-size: 0.9rem;
  opacity: 0.6;
`;

const AppFeatureImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const AppFeatureItemContainer = styled.div`
  width: 340px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;

  @media (min-width: 768px) {
    width: 300px;
  }
`;